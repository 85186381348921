import Vue from 'vue';
import MenuIcon from '../../../node_modules/vue-material-design-icons/Menu.vue';
import LogoutIcon from '../../../node_modules/vue-material-design-icons/Logout.vue';
import ChevronUp from '../../../node_modules/vue-material-design-icons/ChevronUp.vue';
import ChevronLeft from '../../../node_modules/vue-material-design-icons/ChevronLeft.vue';
import ChevronRight from '../../../node_modules/vue-material-design-icons/ChevronRight.vue';
import ChevronDoubleLeft from '../../../node_modules/vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronDoubleRight from '../../../node_modules/vue-material-design-icons/ChevronDoubleRight.vue';
import AirplaneTakeoff from '../../../node_modules/vue-material-design-icons/AirplaneTakeoff.vue';

Vue.component('menu-icon', MenuIcon);
Vue.component('logout-icon', LogoutIcon);
Vue.component('chevron-up', ChevronUp);
Vue.component('chevron-left', ChevronLeft);
Vue.component('chevron-right', ChevronRight);
Vue.component('chevron-double-left', ChevronDoubleLeft);
Vue.component('chevron-double-right', ChevronDoubleRight);
Vue.component('airplane-takeoff', AirplaneTakeoff);
