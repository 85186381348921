import axios from 'axios';
import { Message } from 'element-ui';
// import { getTokenAccess } from '@/utils/auth';
import Config from '@/config';
import { trace } from '@/utils/trace';

// create an axios instance
const service = axios.create({
  baseURL: Config.authUrl + '/api/v1/version',
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (conf) => {
    // トークンを含まない
    // if (store.getters.token) {
    //   config.headers['Authorization'] = 'Bearer ' + getTokenAccess();
    // }
    conf.baseURL = Config.authUrl + '/api/v1/versions';
    return conf;
  },
  (error) => {
    // Do something with request error
    trace(error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  (response) => response,

  (error) => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
