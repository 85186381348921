import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import { getTokenAccess, getTokenRefresh } from '@/utils/auth';
import router from '@/router';
import Config from '@/config';
import { trace } from '@/utils/trace';

let isAlreadyFetchingAccessToken = false;
// let subscribers = [];

// function onAccessTokenFetched(accessToken) {
//   subscribers = subscribers.filter((callback) => callback(accessToken));
// }

// function addSubscriber(callback) {
//   subscribers.push(callback);
// }

// create an axios instance
const service = axios.create({
  baseURL: Config.authUrl + '/api/v1/auth',
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (conf) => {
    // Do something before request is sent
    if (store.getters.token) {
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      conf.headers['Authorization'] = 'Bearer ' + getTokenAccess();
    }
    conf.baseURL = Config.authUrl + '/api/v1/auth';
    return conf;
  },
  (error) => {
    // Do something with request error
    trace(error); // for debug
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  (response) => response,

  (error) => {
    // const {
    //   config: conf,
    //   response: { status },
    // } = error;
    trace('error: ', error);
    trace('conf: ', error.config);
    const conf = error.config;
    const status = error.response.status;
    const originalRequest = conf;

    if (status === 401) {
      trace('isAlreadyFetchingAccessToken', isAlreadyFetchingAccessToken);
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        const refreshToken = getTokenRefresh();

        return new Promise((resolve, reject) => {
          store
            .dispatch('LoginRefresh', refreshToken)
            .then(() => {
              const accessToken = getTokenAccess();
              trace('requestAuthWithToken: 70');
              originalRequest.headers.Authorization = 'Bearer ' + accessToken;
              return resolve(axios.request(originalRequest));
              // return Promise.resolve();
            })
            .catch(() => {
              trace(error); // for debug
              trace('login refresh catch');

              store.dispatch('FedLogOut').then(() => {
                Message({
                  message: error.message,
                  type: 'error',
                  duration: 5 * 1000,
                });
                router.push({ name: 'login' });
              });

              isAlreadyFetchingAccessToken = false;
              return reject(error);
            });
        });
      }

      // return Promise.reject(error);

      // 無限ループに陥ってしまうのでコメントアウト
      // const retryOriginalRequest = new Promise((resolve) => {
      //   addSubscriber((accessToken) => {
      //     originalRequest.headers.Authorization = 'Bearer ' + accessToken;
      //     return resolve(axios(originalRequest));
      //   });
      // });
      // return retryOriginalRequest;
    } else {
      trace('err' + error); // for debug
      trace('401 else');
      // Message({
      //   message: error.message,
      //   type: 'error',
      //   duration: 5 * 1000,
      // });
      return Promise.reject(error);
    }
  }
);

export default service;
