import moment from 'moment';

// set function parseTime,formatTime to filter
export { getReadableFileSizeString, utc2local, parseTime, formatTime } from '@/utils/index';

function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/* 数字 格式化*/
export function numberFormatter(num, digits = 1) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
        si[i].symbol
      );
    }
  }
  return String(num);
}

export function toThousandslsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','));
}

function utc2local(utcStr) {
  var utc = new Date(utcStr);
  var localZone = new Date();
  var local = utc.getTime() - localZone.getTimezoneOffset() * 60 * 1000;
  return local;
}

export function utc2localFilter(utcStr) {
  return utc2local(utcStr);
}

function getReadableFileSizeString(fileSizeInBytes) {
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export function sizeFilter(size) {
  return getReadableFileSizeString(size);
}

export function toLocaleString(num) {
  if (isNaN(num)) {
    return '';
  }
  return num.toLocaleString();
}

export function checkChildProperty(obj, key) {
  return key.split('.').reduce(function(o, x) {
    return typeof o === 'undefined' || o === null ? o : o[x];
  }, obj);
}

export function toDisplayOperated(val) {
  if (val === null) {
    return '';
  } else {
    return val;
  }
}

export function round(number, precision) {
  var shift = function(number, precision, reverseShift) {
    if (reverseShift) {
      precision = -precision;
    }
    var numArray = ('' + number).split('e');
    return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + precision : precision));
  };
  return shift(Math.round(shift(number, precision, false)), precision, true);
}

export function percentRound(value, precision = 0) {
  return round(value * 100, precision);
}

export function formatJson(jsonRaw) {
  if (jsonRaw === undefined || jsonRaw === null) {
    return '';
  }
  const obj = JSON.parse(jsonRaw);
  return JSON.stringify(obj, null, '  ');
}

export function percentageFormat(val) {
  if (!val || isNaN(val) || !isFinite(val)) {
    return 0.0;
  }
  return Math.round(val * 100) / 100;
}

export function colorWithSharp(val) {
  return '#' + val;
}

export function normalizeColor(val) {
  if (val && val.length == 8) {
    return val.substr(2);
  }
  return val;
}

export function stringFormat(str, args) {
  return str.replace('${}', args);
}

export function localeFromDate(date) {
  // 実行環境のタイムゾーンが使われる
  return moment(date).format('YYYY/MM/DD HH:mm');
}

export function localeFromUTCString(date) {
  // UTC時刻に9時間を足して返す
  return (
    moment(date)
      // .add(9, 'h')
      .format('YYYY/MM/DD HH:mm')
  );
}

export function localeDateFromUTCString(date) {
  // UTC時刻に9時間を足して返す
  return (
    moment(date)
      // .add(9, 'h')
      .format('YYYY/MM/DD')
  );
}

export function localeDateFromUNIXTime(unixtime) {
  let date = new Date(unixtime * 1000);
  return moment(date).format('YYYY/MM/DD');
}

export function zeroPad(value, num) {
  num = typeof num !== 'undefined' ? num : 2;
  return value.toString().padStart(num, '0');
}
