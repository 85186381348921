export default {
  route: {
    siteTitle: 'POODL',
    login: 'Login',
    organization: 'Organization',
    about: 'About us',
    dashboard: 'Dashboard',
    introduction: 'Introduction',
    documentation: 'Documentation',
    storage: 'Storage',
    category: 'Category',
    categoryDetail: 'Category Detail',
    categoryDetailTitle: '{name} | Category Detail',
    label: 'Label',
    dataset: 'Dataset',
    datasetDetail: 'Dataset Detail',
    datasetDetailTitle: '{name} | Dataset Detail',
    datasetDetailImage: 'Image Data',
    datasetDetailEdit: 'Dataset Detail Edit',
    datasetDetailEditTitle: '{name} | Dataset Detail Edit',
    datasetCreate: 'Dataset Create',
    dataSeparation: 'Data Separation',
    library: 'Library',
    annotation: 'Annotation',
    annotationDetail: 'Annotation Image List',
    annotationDetailTitle: '{name} | Annotation Image List',
    annotationDetailImage: 'Annotation Image Detail',
    annotationDetailImageTitle: '{name}[{key}] | Annotation Image Detail',
    preprocessing: 'Preprocessing',
    assignment: 'Data splitting',
    compute: 'Compute',
    computeJob: 'Compute Job',
    computeJobCreate: 'Start Job',
    computeJobDetail: 'Job Detail',
    computeJobDetailTitle: '{name} | Job Detail',
    computeJobDetailImage: 'Job Result Image',
    computeJobOutput: 'Job Output',
    computeJobLog: 'Job Log',
    computeJobReport: 'Report Job',
    computeCondition: 'Compute Condition',
    computeConditionCreate: 'New Condition',
    computeConditionEdit: 'Edit Condition',
    computeConditionDetail: 'Condition Detail',
    computeConditionDetailTitle: '{name} | Condition Detail',
    computeNetwork: 'Compute Network',
    computeNetworkUpload: 'Upload New Network',
    computeNetworkCreate: 'Create New Network',
    computeNetworkDetail: 'Network Detail',
    computeNetworkDetailTitle: '{name} | Network Detail',
    computeLibrary: 'Compute Library',
    computeLibraryCreate: 'New Library',
    computeLibraryDetail: 'Library Detail',
    computeLibraryDetailTitle: '{name} | Library Detail',
    computeJobCheckValidate: 'Enable validation',
    job: 'Job',
    analysis: 'Analysis',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    componentIndex: 'Introduction',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    countTo: 'CountTo',
    componentMixin: 'Mixin',
    backToTop: 'BackToTop',
    dragDialog: 'Drag Dialog',
    charts: 'Charts',
    keyboardChart: 'Keyboard Chart',
    lineChart: 'Line Chart',
    mixChart: 'Mix Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    Table: 'Table',
    dynamicTable: 'Dynamic Table',
    dragTable: 'Drag Table',
    inlineEditTable: 'Inline Edit',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Article List',
    error: 'Error!',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    zip: 'Zip',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    name: 'Name',
    description: 'Description',
    created: 'Created',
    createdAt: 'Created at',
    started: 'Started',
    startedAt: 'Started at',
    updated: 'Updated',
    updatedAt: 'Updated at',
    analysisUpdated: 'Updated analysis',
    update: 'Update',
    updateAndRun: 'Update and run',
    register: 'Register',
    actions: 'Actions',
    revision: 'Revision',
    imageCount: 'Count',
    origin: 'Origin',
    originJob: 'Origin Job',
    size: 'Size',
    progress: 'Progress',
    show: 'Show',
    status: 'Status',
    operated: 'operated',
    exit: 'Exit',
    condition: 'Condition',
    finished: 'Finished',
    elapsed: 'Elapsed',
    list: 'List',
    previous: 'Previous',
    next: 'Next',
    create: 'Create',
    createAndRun: 'Create and run',
    run: 'Run',
    upload: 'Upload',
    tag: 'Tag',
    key: 'Key',
    layerCount: 'Number of Image',
    weight: 'Weight',
    image: 'Image',
    imageTitle: '{name}[{key}] | Image',
    layer: 'Layer',
    ok: 'OK',
    cancel: 'Cancel',
    remove: 'Remove',
    archive: 'Archive',
    restoreArchived: 'Restore archive',
    edit: 'Edit',
    copy: 'Copy',
    dropFileHere: 'Drop file here or <em>click to upload</em>',
    network: 'Network',
    comment: 'Comment',
    file: 'File',
    content: 'Content',
    inherited: 'Inherited',
    exitCode: 'Exit Code',
    format: 'Format',
    data: 'Data',
    attribute: 'Attribute',
    download: 'Download',
    order: 'Order',
    color: 'Color',
    setting: 'Settings',
    clear: 'Clear',
    displayLatestRevision: 'Visible latest revision only',
    username: 'Username',
    memo: 'Memo',
    role: 'Role',
    settings: 'Settings',
    settingsProfile: 'Profile',
    settingsUser: 'User Settings',
    settingsGroup: 'Group Settings',
    settingsUtilization: 'Utilization',
    settingsHistory: 'History',
    settingsSecurity: 'Security',
    registerUser: 'User registration',
    close: 'Close',
    append: 'Append',
    answerResult: 'Answer',
    predictResult: 'Predict',
    errValidationFailed: 'Validation failed!',
    loading: 'Loading...',
    processing: 'Processing...',
    yearMonth: 'year.month',
    copyToClipboard: 'Copy to clipboard',
    msgCopiedToClipboard: 'Copied to clipboard!',
    searchResult: 'keyword: {keyword}',
    reload: 'Reload',
    currentlySelectedDataset: 'Currently selected',
    metadata: 'Metadata',
    free: 'Free',
    sec: 'sec.',
    success: 'success',
    template: 'template',
    date: {
      yearsBefore: '{years} y ago',
      monthsBefore: '{months} m ago',
      weeksBefore: '{weeks} w ago',
      daysBefore: '{days} d ago',
      hoursBefore: '{hours} h ago',
      minsBefore: '{mins} min ago',
      secsBefore: '{secs} s ago',
    },
    getOutputListFailed: 'Cannot get output list',
    unknownClass: 'Unknown class',
  },
  dashboard: {
    createDataset: 'Create Dataset',
    showDataset: 'Show Dataset',
    goAnnotation: 'Go Annotation',
    runLearning: 'Run Learning',
  },
  category: {
    count: 'Categories',
    createCategory: 'Create Category',
    imageCount: 'Image Count',
    editCategory: 'Edit Category',
    deleteCategory: 'Delete Category',
    createLabel: 'Create Label',
    editLabel: 'Edit Label',
    downloadLabelCSV: 'Download Label CSV',
    deleteLabel: 'Delete Label',
    search: 'Search Category',
    add: 'Add Category',
    deleteCategoryDescription: 'Are you sure to delete this category?',
    changeLabelOrderSuccess: 'Change label order success',
    changeLabelOrderFailed: 'Change label order failed',
    getCategoryFailed: 'Cannot get category',
    getCategoryListFailed: 'Cannot get category list',
    addCategoryFailed: 'Could not add category',
    addCategorySuccess: 'Add category success',
    editCategoryFailed: 'Could not edit category',
    editCategorySuccess: 'Edit category success',
    deleteCategoryFailed: 'Could not delete category',
    deleteCategorySuccess: 'Delete category success',
    addLabelFailed: 'Could not add label',
    addLabelSuccess: 'Add label success',
    updateLabelFailed: 'Could not update label',
    updateLabelSuccess: 'Update label success',
    deleteLabelFailed: 'Could not delete label',
    deleteLabelSuccess: 'Delete label success',
    countLabel: 'Number of label',
  },
  dataset: {
    editing: 'Editing',
    count: 'Datasets',
    createDataset: 'Create Dataset',
    name: 'Dataset Name',
    imageCount: 'Number of image',
    selectCategory: 'Select Category',
    removeCategory: 'Remove Category',
    noCategory: 'No category created!',
    noSelectCategory: 'No category selected!',
    noSelectLabel: 'Select All Labels!',
    goEdit: 'Go edit',
    goAnnotation: 'Go annotation',
    takeOverLabels: 'Take over annotation information',
    goAssignment: 'Splitting data',
    goCompute: 'Go training',
    goReCategorize: 'Exchange category labels',
    doneEditing: 'Done Editing',
    doneEditingDescription: 'Are you sure to finish edit mode?',
    editMode: 'Edit Mode',
    editModeDescription: 'Are you sure to move edit page?',
    annotationMode: 'Annotation Mode',
    annotationModeDescription: 'Are you sure to move annotation page?',
    changeCategoryMode: 'Change Category Mode',
    changeCategoryModeDescription: 'Select the category you want to change',
    noChangeCategory: 'The category has not changed!',
    currentCategory: 'Current Category',
    changeCategory: 'Category you want to change',
    assignmentDescription: 'Are you sure to split data?',
    getAssignmentCountFailed: 'Cannot get assignment count',
    editSetting: 'Dataset edit settings',
    groupHead: 'Number of prefix character for grouping',
    groupingMultiImage: 'Grouping file name character counted ${}',
    deleteDataset: 'Delete Dataset',
    deleteAllDataset: 'Delete All Dataset',
    deleteAllDatasetWarning: 'The batch delete dataset function deletes all related datasets!',
    detail: 'Dataset Detail Information',
    eachCategory: 'unbiased separation on each category',
    editDataset: 'Edit Dataset',
    editModeInitKey: 'Do you want to copy the dataset? New dataset key will be generated.',
    rateOfTraining: 'Rate of training data',
    downloadAnnotationList: 'Download annotation list',
    downloadCompress: 'Download compressed dataset',
    msgConfirmCreateDownload: 'Are you sure to prepare compressed dataset file to download.',
    msgCreateDownload:
      'Starting compression for downloading dataset. Notification email will be sent after done.',
    errCreateDownload: 'Failed to start for downloading dataset!',
    annotationListType: {
      both: 'Uploaded file name + remote server ID',
      bothDescription:
        'A character string in which the upload file name and the identification name on the server are concatenated with an underscore',
      remotePath: 'Remote serve ID',
      remotePathDescription: 'Distinguished name for learning process on the server',
      localName: 'Uploaded file name',
      localNameDescription: 'File name when uploaded',
    },
    deleteDatasetConfirm: 'Are you sure to delete this dataset?',
    search: 'search dataset',
    deleteAllDatasetsConfirm: 'Are you sure you want to delete this and all related datasets?',
    getDatasetFailed: 'Cannot get dataset',
    addDatasetFailed: 'Could not add dataset',
    addDatasetSuccess: 'Add dataset success',
    editDatasetFailed: 'Could not edit dataset',
    editDatasetSuccess: 'Edit dataset success',
    deleteDatasetFailed: 'Could not delete dataset',
    deleteDatasetSuccess: 'Delete dataset success',
    findDatasetFailed: 'Datset could not be found',
    beginEditFailed: 'Could not begin edit mode',
    beginAnnotationFailed: 'Could not begin annotation mode',
    noImage: 'There is no image',
    splittingDataFailed: 'Could not do assignment (data splitting)',
    findUploadingFileFailed: 'Uploading file does not exist',
    wrongUploadingFile: 'Uploading file has something to wrong',
    changeCategoryFailed: 'Change category failed',
    changeCategorySuccess: 'Change category success',
    uploadImageFailed: 'New image cannot uploaded!!',
    deleteImageFailed: 'Could not remove image',
    finishEditOperationFailed: 'This edit operation could not be finished!',
    finishEditModeFailed: 'Could not end edit mode',
    divideDatasetSuccess: 'Divide dataset success',
    divideDatasetFailed: 'Divide dataset failed',
    concatenateDatasetSuccess: 'Concatenate dataset success',
    concatenateDatasetFailed: 'Concatenate dataset failed',
    simpleEdit: 'Simple Edit',
  },
  preprocessing: {
    concat: 'Concatenate',
    concatDatasets: 'Concatenate Selected Datasets ({count})',
    concatConfirm: 'Are you sure to concat these datasets?',
    delete: 'Delete',
    deleteDatasets: 'Delete Selected Datasets',
    deleteOneDatasetConfirm: 'Are you sure to delete this dataset?',
    deleteDatasetsConfirm: 'Are you sure to delete these datasets?',
    divide: 'Divide',
    divideDataset: 'Divide Dataset',
    divideDatasetMessage: 'This dataset will be divided into each label set below.',
    divideConfirm: 'Are you sure to divide this dataset?',
    transform: 'Transform',
    transformDataset: 'Transform Dataset',
    transformDatasetMessage:
      "The data of this dataset's labels will be integrated into each selected label below.",
    transformConfirm: 'Are you sure to transform this dataset?',
    transformProcessSuccess: 'This transform process was successed.',
    transformProcessFailed: 'This transform process was failed.',
    deleteOnTransform: 'Delete',
    toLabel: 'To label',
    transformNewName: 'New transformed dataset',
  },
  annotation: {
    count: 'Annotations',
    imageCount: 'Count',
    cancel: 'Cancel',
    category: 'Category',
    done: 'Done Annotation',
    doneDescription: 'Are you sure to finish annotation?',
    doneFailed: 'This annotation operation could not be finished!',
    continueFailed: 'Unlabeled image was not found',
    addLabelFailed: 'Could not add label',
    continue: 'Continue',
    filename: 'Filename',
    autoScrollOn: 'Turn on autoscroll',
    autoScrollOff: 'Turn off autoscroll',
    getImageFailed: 'Cannot get image',
    getCategoryFailed: 'Cannot get category',
    detachLabelFailed: 'Cannot detach label',
    cannotGetLabelCount: 'Cannot get label count',
  },
  compute: {
    job: {
      count: 'Job count',
      startJob: 'Start Job',
      copyJob: 'Copy job attributes',
      name: 'Job Name',
      edit: 'Edit Job',
      confirmCancel: 'Are you sure to cancel this job?',
      confirmRemove: 'Are you sure to remove this job?',
      detail: 'Job detail information',
      summary: 'Summary',
      process: 'Process',
      confusion_matrix: 'Confusion_matrix',
      twodmap: '2D map',
      stat: 'Stat',
      answerLabel: 'answer labels',
      predictLabel: 'predict labels',
      createLibrary: 'Create library',
      confirmCreateLibrary: 'Are you sure to create new library from the trained model?',
      errOutputClassNum: "The number of dataset's classes must be equal to that of network's!",
      errInputChannel: "Dataset's input channel must be equal to network's!",
      newName: 'Copied {prev}',
      errGetList: 'Cannot get job list.',
      search: 'search job',
      label: 'Label Name',
      correctLabelNum: 'Number of correct labels',
      incorrectLabelNum: 'Number of いncorrect labels',
      correctRate: 'Correct answer rate',
      pdf: 'Print to PDF',
      recall: 'based on recall',
      precision: 'based on precision',
      findJobFailed: 'Job could not be found',
      findJobPredictFailed: 'Job predict information was not found',
      findJobDatasetImageFailed: 'Job dataset image list was not found',
      deleteJobFailed: 'Cannot remove this job',
      cancelJobFailed: 'Cannot cancel this job',
      cancelJobSuccess: 'Canceled this job',
      updateJobFailed: 'Cannot update this job',
      updateJobSuccess: 'Job updated',
      archiveJobFailed: 'Cannot archive this job',
      restoreArchivedJobFailed: 'Cannot restore this archived job',
      runJobFailed: 'Cannot run this job',
      createJobFailed: 'Cannot create this job',
      getLogFailed: 'Cannot get log list',
      trainAccLabel: 'Training accuracy',
      ValAccLabel: 'Validation accuracy',
      MacroFLabel: 'Macro F-measure',
    },
    condition: {
      count: 'Number of condition',
      create: 'Create Condition',
      name: 'Condition Name',
      confirmRemove: 'Are you sure to remove this condition?',
      goCompute: 'Go training',
      search: 'Sarch Condition',
      simpleEditing: 'Simple Editing',
      detailEditing: 'Detailed Editing',
      simpleInfo: 'Simple Information',
      detailInfo: 'Detailed Information',
      epoch: 'epoch',
      height: 'height',
      width: 'width',
      channels: 'number of channels',
      learningSchedule: 'learning schedule',
      initialLearningRate: 'initial rate',
      learningStep: 'step',
      learningRate: 'rate',
      createConditionFailed: 'Cannot create condition',
      submitFailed: 'Cannot submitting content',
      submitSuccess: 'Done submitting content',
      getConditionInformationFailed: 'Condition information was not found',
      deleteConditionFailed: 'Cannot delete condition',
      updateConditionFailed: 'Cannot update condition',
      getConditionFailed: 'Cannot get condition',
      goCopy: 'Make Copy',
      goEdit: 'Complete Edit',
    },
    network: {
      count: 'Number of network',
      createFromPreset: 'Create a network from presets',
      createFromJson: 'Create a network from Json',
      name: 'Network Name',
      structure: 'Structure',
      confirmRemove: 'Are you sure to remove this network?',
      goCompute: 'Go training',
      preset: 'Model Preset',
      input: 'Input',
      inputImageSize: 'Input image size',
      channel: 'Channel',
      output: 'Output',
      classnum: 'Number of Classes',
      character: 'characters',
      errorNotGettingPresetList: 'Cannot get member list.',
      errorNotGettingNetwork: 'Failed getting network structure.',
      errorNotCreateNetwork: 'Failed submitting content.',
      successCreateNetwork: 'Done submitting content.',
      graph: 'Network Graph',
      info: 'Network Structure Infomation',
      copyStructure: 'Copying Info to Clipboard',
      successCopyStructure: 'Success Copying Structure Info to Clipboard',
      errorNotGetNetworkDetail: "Couldn't getting Network information.",
      errorNotGetNetworkSummary: "Couldn't getting Network information.",
      errorNotGetNetworkGraph: "Couldn't getting Network information.",
      errorNotRemoveNetwork: 'Cannot remove this network.',
      search: 'Search Network',
      findNetworkFailed: 'Network could not be found',
      getNetworkListFailed: 'Cannot get network list',
      origin: 'Origin Network',
    },
    library: {
      count: 'Number of library',
      create: 'Upload Library',
      name: 'Library Name',
      input: 'Input',
      inputImageSize: 'Input image size',
      channel: 'Channel',
      output: 'Output',
      classnum: 'Number of Classes',
      fixedLayers: 'Fixed layers',
      fixedLayersEnabled: 'Enable to fix layers',
      fixedLayersDisabled: 'diable to fix layers',
      confirmRemove: 'Are you sure to remove this library?',
      goCompute: 'Go training',
      search: 'Search Library',
      findLibraryFailed: 'Library could not be found',
      submitFailed1: 'Failed submitting content (1)',
      submitFailed2: 'Failed submitting content (2)',
      submitFailed3: 'Failed submitting content (3)',
      submitSuccess: 'Done submitting content.',
      unSelectedFile: 'File as not been selected',
      deleteLibraryFailed: 'Cannot remove this library',
      getLibraryListFailed: 'Cannot get library list',
    },
    output: {
      name: 'Output',
      report: 'Report',
    },
    log: {
      name: 'Log',
    },
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    github: 'Github',
    screenfull: 'screenfull',
    theme: 'theme',
    settings: 'Settings',
    switchLanguage: 'switch language success',
    screenfullFailed: 'you browser can not work',
  },
  login: {
    title: 'Login Form',
    logIn: 'Log in',
    organization: 'Organization',
    username: 'Username',
    password: 'Password',
    mailaddress: 'mailaddress',
    any: 'any',
    thirdparty: 'Or connect with',
    thirdpartyTips:
      'Can not be simulated on local, so please combine you own business simulation! ! !',
    organizationTitle: 'Login to your organization',
    organizationSubmit: 'Next',
    backToSelect: 'Back to select organization',
    resetPass: 'Reset Password',
    registerIpAddress: 'Register IP address',
    backToLoginForm: 'Back to Login form',
    sendResetPassMail: 'Send Email to Reset Password',
    invalidDataFormat: 'Invalid Data Format',
  },
  documentation: {
    documentation: 'Documentation',
    github: 'Github Repository',
  },
  permission: {
    roles: 'Your roles',
    switchRoles: 'Switch roles',
  },
  guide: {
    description:
      'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide',
  },
  components: {
    documentation: 'Documentation',
    tinymceTips:
      'Rich text editor is a core part of management system, but at the same time is a place with lots of problems. In the process of selecting rich texts, I also walked a lot of detours. The common rich text editors in the market are basically used, and the finally chose Tinymce. See documentation for more detailed rich text editor comparisons and introductions.',
    dropzoneTips:
      'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    backToTopTips1:
      'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2:
      'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips:
      'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.',
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Imp',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    reviewer: 'reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description:
      'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction',
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name(default file)',
  },
  theme: {
    change: 'Theme change',
    documentation: 'Theme documentation',
    tips:
      'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.',
  },
  tagsView: {
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All',
  },
  settings: {
    profile: 'Profile',
    users: 'User',
    groups: 'Group',
    notification: 'Notification',
    utilization: 'Utilization',
    download: 'Download',
    history: 'History',
    security: 'Security',
    primary: 'Primary',
    deletePrimaryErrorMessage: 'Can not delete Primary email address',
    deleteOnlyEmailErrorMessage: 'Can not delete',
    addEmail: 'Add new Email addres',
    save: 'Save',
    addUser: 'Add new User',
    name: 'Name',
    username: 'Username',
    email: 'Email',
    user: 'User',
    role: 'Role',
    group: 'Group',
    ip: 'IP',
    addGroup: 'Add new group',
    updateGroup: 'Update group information',
    memo: 'Memo',
    resource: 'Resource',
    auth: 'Auth',
    action: 'Action',
    create: 'Create, Update',
    delete: 'Delete',
    get_info: 'Check',
    get_list: 'Check List',
    change: 'Change',
    permission: 'Permission',
    permit: 'Permit',
    prohibit: 'Prohibit',
    updateUser: 'Update user information',
    dataset: 'Dataset',
    update: 'Update',
    edit: 'Edit',
    annotation: 'Annotation',
    assignment: 'Assignment',
    category: 'Category',
    batchml: 'Job',
    create_condition: 'Create condition',
    delete_condition: 'Delete condition',
    get_info_condition: 'Check condition',
    create_library: 'Create library',
    delete_library: 'Delete library',
    get_info_library: 'Check library',
    create_network: 'Create network',
    delete_network: 'Delete network',
    get_info_network: 'Check network',
    run_job: 'Run job',
    get_info_job: 'Check job',
    get_info_output: 'Check output',
    delete_output: 'Delete output',
    storage: 'Storage',
    preprocess: 'Preprocess',
    concat: 'Concat',
    divide: 'Divide',
    utilInfo: 'Utilization',
    storageInfo: 'Storage',
    storageUsed: 'Using',
    storageRemaining: '{msg} remaining',
    learningTime: 'Learning Time',
    numOfImgs: 'Number of All Images',
    numOfDatasets: 'Number of Datasets',
    numOfUsers: 'Number of Users',
    transform: 'Transform',
    password: 'Password',
    changePassword: 'Change password',
    unconfirmed: 'Unconfirmed',
    registerPassword: 'Register password',
    datetime: 'Datetime',
    actionType: 'Action type',
    jobCredit: 'Learning job credit',
    jobBalance: 'Learning job balance',
    jobBalanceMonthly: 'Learning job monthly balance',
    expiredDatetime: 'Expired at',
    enabled: 'Enabled',
    disabled: 'Disabled',
    availableTime: 'Available time',
    elapsedTime: 'Elapsed time',
    jobCount: 'Job count',
    badgetTime: 'Initial time',
    userStatus: 'User Status',
    userEnable: 'enable',
    userDisable: 'disable',
    getProfileFailed: 'Cannot get profile',
    getStorageInformationFailed: 'Cannot get storage information',
    getBalanceListFailed: 'Cannot get balance list',
    getBalanceMonthlyInformationFailed: 'Cannot get balance monthly information',
    userDetail: {
      registerUserSuccess: 'User regsiteration was success',
      errRegister: 'Failed to add user!',
      msgUpdate: 'Updated user information.',
      errUpdate: 'Failed to update user!',
      deleteUserDescription: 'Are you sure to delete this user?',
      deleteUserSuccess: 'Delete user success',
      deleteUserFailed: 'Delete user failed',
      deleteGroupDescription: 'Are you sure to delete this group?',
      deleteGroupSuccess: 'Delete group success',
      deleteGroupFailed: 'Delete group failed',
      sendRegistrationEmail: 'Registration email was sended',
      getMemberListFailed: 'Cannot get member list',
      changePasswordFailed: 'Could not change password',
      changePasswordSuccess: 'Password changed!',
    },
    downloadDetail: {
      type: 'Type',
      title: 'Title',
      status: 'Status',
      errDownloadRaw: 'Failed to prepare download file!',
      msgCancel: 'Process was canceled.',
      errCancel: 'Process could not be canceled!',
      msgConfirmCancel: 'Are you sure to cancel this process?',
      msgDelete: 'Downloading file was deleted.',
      errDelete: 'Downloading file could not be deleted.',
      msgConfirmDelete: 'Are you sure to delete this file?',
    },
    securityDetail: {
      ipAddress: 'IP address',
      registeredUser: 'Registered user',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      enableOrDisable: 'Enable/Disable',
      buttonDisable: 'Disable',
      buttonEnable: 'Enable',
      buttonDelete: 'Remove',
      dialogTitleDisable: 'Disable IP address',
      dialogMessageDisable: 'Are you sure you want to diable the IP address?',
      dialogTitleEnable: 'Enable IP address',
      dialogMessageEnable: 'Are you sure you want to enable the IP address?',
      dialogTitleDelete: 'IP address removing',
      dialogMessageDelete: 'Are you sure you want to delete the IP address?',
      titleIpAddressRegistration: 'Register IP address',
      buttonClear: 'Clear',
      buttonRegister: 'Register',
      currentAccessIpAddress: 'Current IP address',
      buttonSetCurrentAccessIpAddress: 'Set',
      titleIpAddressList: 'List of registered IP address',
      msgRegistered: 'IP address ({ipAddress}) was registered.',
      errMsgCouldNotRegister: 'IP address ({ipAddress}) could not be registered!',
      errMsgCouldNotDisable: 'IP address ({ipAddress}) could not disable!',
      sendEmail: 'Send email',
      approveAddIpAddressSuccess: 'Successful approval of additional Ip address',
      invalidURL: 'Invalid URL',
    },
  },
};
