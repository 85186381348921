import Config from '@/config';

/* eslint-disable no-console */
export default {
  methods: {
    /** デバッグモードでConsoleAPIが有効な場合にログを出力する */
    trace(...s) {
      if (Config.debugViewer && typeof console.log != 'undefined') {
        console.log(...s);
      }
    },
  },
};
