import { getDomainOrganization } from '@/utils/organization';
import { loginByUsername, loginRefresh, getUserInfo } from '@/api/login';
import {
  getTokenAccess,
  getTokenRefresh,
  getTokenExpire,
  setTokenAccess,
  setTokenRefresh,
  setTokenExpire,
  removeTokenAccess,
  removeTokenRefresh,
  removeTokenExpire,
} from '@/utils/auth';
// import { trace } from '@/utils/trace';

const user = {
  state: {
    user: '',
    status: '',
    code: '',
    tokenAccess: getTokenAccess(),
    tokenRefresh: getTokenRefresh(),
    tokenExpire: getTokenExpire(),
    name: '',
    key: '',
    email: '',
    groupKey: '',
    username: '',
    organization: '',
    avatar: '',
    introduction: '',
    roles: [],
    setting: {
      articlePlatform: [],
    },
    enabled: '',
  },

  mutations: {
    SET_CODE: (state, code) => {
      state.code = code;
    },
    SET_TOKEN_ACCESS: (state, token) => {
      state.tokenAccess = token;
    },
    SET_TOKEN_REFRESH: (state, token) => {
      state.tokenRefresh = token;
    },
    SET_TOKEN_EXPIRE: (state, expire) => {
      state.tokenExpire = expire;
    },
    SET_ORGANIZATION: (state, organization) => {
      state.organization = organization;
    },
    SET_INTRODUCTION: (state, introduction) => {
      state.introduction = introduction;
    },
    SET_SETTING: (state, setting) => {
      state.setting = setting;
    },
    SET_STATUS: (state, status) => {
      state.status = status;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_NAME: (state, name) => {
      state.name = name;
    },
    SET_USERNAME: (state, username) => {
      state.username = username;
    },
    SET_KEY: (state, key) => {
      state.key = key;
    },
    SET_EMAIL: (state, email) => {
      state.email = email;
    },
    SET_GROUP_KEY: (state, groupKey) => {
      state.groupKey = groupKey;
    },
    SET_ENABLED: (state, enabled) => {
      state.enabled = enabled;
    },
  },

  actions: {
    SetOrganization({ commit }, organization) {
      return new Promise((resolve, reject) => {
        const subdomain = getDomainOrganization();
        if (subdomain !== organization) {
          reject();
        }
        commit('SET_ORGANIZATION', subdomain);
        resolve();
      });
    },
    // ログイン認証
    LoginByUsername({ commit }, userInfo) {
      const organization = userInfo.organization.trim();
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        loginByUsername(organization, username, userInfo.password)
          .then((response) => {
            const data = response.data;
            commit('SET_TOKEN_ACCESS', data.access_token);
            commit('SET_TOKEN_REFRESH', data.refresh_token);
            commit('SET_TOKEN_EXPIRE', data.expires.date);
            setTokenAccess(data.access_token);
            setTokenRefresh(data.refresh_token);
            setTokenExpire(data.expires.date);

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    LoginSave({ commit }, data) {
      return new Promise((resolve) => {
        commit('SET_TOKEN_ACCESS', data.access_token);
        commit('SET_TOKEN_REFRESH', data.refresh_token);
        commit('SET_TOKEN_EXPIRE', data.expires.date);
        setTokenAccess(data.access_token);
        setTokenRefresh(data.refresh_token);
        setTokenExpire(data.expires.date);
        resolve();
      });
    },

    LoginRefresh({ commit }, tokenRefresh) {
      return new Promise((resolve, reject) => {
        loginRefresh(tokenRefresh)
          .then((response) => {
            const data = response.data;
            commit('SET_TOKEN_ACCESS', data.access_token);
            commit('SET_TOKEN_REFRESH', data.refresh_token);
            commit('SET_TOKEN_EXPIRE', data.expires.date);
            setTokenAccess(data.access_token);
            setTokenRefresh(data.refresh_token);
            setTokenExpire(data.expires.date);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    FetchAccessToken({ state }) {
      return new Promise((resolve) => {
        resolve(state.tokenAccess);
      });
    },

    // 获取用户信息
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then((response) => {
            if (!response.data) {
              // 由于mockjs 不支持自定义状态码只能这样hack
              reject(new Error('error'));
            }
            const user = response.data.user;

            // commit('SET_ROLES', ['admin']);
            commit('SET_NAME', user.name);
            commit('SET_KEY', user.key);
            commit('SET_USERNAME', user.username);
            commit('SET_EMAIL', user.email);
            commit('SET_GROUP_KEY', user.group_key);
            commit('SET_ORGANIZATION', user.organization_key);
            commit('SET_ENABLED', user.enabled);
            // commit('SET_AVATAR', data.avatar)
            // commit('SET_INTRODUCTION', data.introduction)
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            // const tokenRefresh = getTokenRefresh();
            // loginRefresh(tokenRefresh)
            //   .then((response) => {
            //     const data = response.data;
            //     commit('SET_TOKEN_ACCESS', data.access_token);
            //     commit('SET_TOKEN_REFRESH', data.refresh_token);
            //     commit('SET_TOKEN_EXPIRE', data.expires.date);
            //     setTokenAccess(data.access_token);
            //     setTokenRefresh(data.refresh_token);
            //     setTokenExpire(data.expires.date);

            //     getUserInfo(data.access_token).then((response2) => {
            //       if (!response2.data) {
            //         // 由于mockjs 不支持自定义状态码只能这样hack
            //         reject(new Error('error'));
            //       }
            //       const user = response.data.user;

            //       // commit('SET_ROLES', ['admin']);
            //       commit('SET_NAME', user.name);
            //       commit('SET_KEY', user.key);
            //       commit('SET_USERNAME', user.username);
            //       commit('SET_GROUP_KEY', user.group_key);
            //       commit('SET_ORGANIZATION', user.organization_key);
            //       // commit('SET_AVATAR', data.avatar)
            //       // commit('SET_INTRODUCTION', data.introduction)
            //       resolve(response2);
            //     });
            //   })
            //   .catch((error) => {
            //     reject(error);
            //   });
            // reject(error);
          });
      });
    },

    // 第三方验证登录
    // LoginByThirdparty({ commit, state }, code) {
    //   return new Promise((resolve, reject) => {
    //     commit('SET_CODE', code)
    //     loginByThirdparty(state.status, state.email, state.code).then(response => {
    //       commit('SET_TOKEN', response.data.token)
    //       setToken(response.data.token)
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // 登出
    LogOut({ commit }) {
      return new Promise((resolve) => {
        // logout(state.tokenAccess).then(() => {
        commit('SET_TOKEN_ACCESS', '');
        commit('SET_TOKEN_REFRESH', '');
        commit('SET_TOKEN_EXPIRE', '');
        commit('SET_ROLES', []);
        removeTokenAccess();
        removeTokenRefresh();
        removeTokenExpire();
        resolve();
        // }).catch(error => {
        //   reject(error)
        // })
      });
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN_ACCESS', '');
        commit('SET_TOKEN_REFRESH', '');
        commit('SET_TOKEN_EXPIRE', '');
        removeTokenAccess();
        removeTokenRefresh();
        removeTokenExpire();
        resolve();
      });
    },
    SetRoles({ commit }, roles) {
      return new Promise((resolve) => {
        commit('SET_ROLES', roles);
        resolve();
      });
    },
  },
};

export default user;
