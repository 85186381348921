/* eslint-disable no-console */
import Cookies from 'js-cookie';
import Config from '@/config';

const app = {
  state: {
    sidebar: {
      opened: !+Cookies.get('sidebarStatus'),
      withoutAnimation: false,
    },
    device: 'desktop',
    language: Cookies.get('language') || 'en',
    config: {
      groupHead: Cookies.get('config-groupHead') || 0,
    },
    subdomain: Config.organization || Cookies.get('subdomain') || '',
  },
  mutations: {
    TOGGLE_SIDEBAR: (state) => {
      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
      }
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 1);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language;
      Cookies.set('language', language);
    },
    SET_SUBDOMAIN: (state, subdomain) => {
      state.subdomain = subdomain;
      Cookies.set('subdomain', subdomain);
    },
    CONFIGURATION: (state, data) => {
      console.log('mutations', data);
      console.log('mutations data.key', data.key);
      console.log('mutations state.config[data.key]', state.config[data.key]);
      if (state.config[data.key] === undefined) {
        return;
      }
      state.config[data.key] = data.value;
      Cookies.set('config-' + data.key, data.value);
    },
  },
  actions: {
    toggleSideBar({ commit }) {
      commit('TOGGLE_SIDEBAR');
    },
    closeSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },
    toggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device);
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    setConfiguration({ commit }, data) {
      console.log('app.js', data);
      commit('CONFIGURATION', data);
    },
    setSubdomain({ commit }, subdomain) {
      commit('SET_SUBDOMAIN', subdomain);
    },
    clearSubdomain({ commit }) {
      commit('SET_SUBDOMAIN', '');
    },
  },
};

export default app;
