<template>
  <el-dropdown trigger="click" class="international" @command="handleSetLanguage">
    <div>
      <svg-icon class-name="international-icon" icon-class="language" />
      <i class="el-icon-caret-bottom"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="ja" :disabled="language === 'ja'">
        日本語
      </el-dropdown-item>
      <!-- <el-dropdown-item command="zh" :disabled="language==='zh'">中文</el-dropdown-item> -->
      <el-dropdown-item command="en" :disabled="language === 'en'">
        English
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
  methods: {
    handleSetLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('setLanguage', lang);
      this.$message({
        message: this.$t('navbar.switchLanguage'),
        type: 'success',
      });
    },
  },
};
</script>

<style scoped>
.international-icon {
  font-size: 20px;
  vertical-align: -5px !important;
  cursor: pointer;
}
.el-icon-caret-bottom {
  position: absolute;
  top: 25px;
  right: -20px;
  font-size: 12px;
}
</style>
