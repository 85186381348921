const autoScroll = {
  state: {
    autoScrollState: true,
  },
  mutations: {
    CHANGE_AUTOSCROLL_STATE: (state, value) => {
      state.autoScrollState = value;
    },
  },
};

export default autoScroll;
