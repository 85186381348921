import { getVersions } from '@/api/version';

const version = {
  state: {
    resource: undefined,
    auth: undefined,
    engine: undefined,
  },
  mutations: {
    setResourceVersion(state, version) {
      state.resource = version;
    },
    setAuthVersion(state, version) {
      state.auth = version;
    },
    setEngineVersion(state, version) {
      state.engine = version;
    },
  },
  actions: {
    setVersions({ commit }) {
      return new Promise((resolve, reject) => {
        getVersions()
          .then((response) => {
            const data = response.data;
            commit('setResourceVersion', data.resource);
            commit('setAuthVersion', data.auth);
            commit('setEngineVersion', data.engine);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default version;
