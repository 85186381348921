import Vue from 'vue';
import './registerServiceWorker';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import ElInputTag from 'el-input-tag';

import '@/styles/index.scss'; // global css

import App from './App.vue';
import router from './router';
import store from './store';
import trace from '@/mixins/trace';
import '../node_modules/vue-material-design-icons/styles.css';

import i18n from './lang'; // Internationalization
import './icons'; // icon
import './errorLog'; // error log
import './permission'; // permission control
// import './mock' // simulation data

const p = require('../package.json');

import Config from '@/config';

// Sentry
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
if (Config.sentryDsn) {
  Sentry.init({
    Vue: Vue,
    trackComponents: true,
    dsn: Config.sentryDsn,
    release: 'poodl-view@' + p.version,
    environment: Config.environment || 'local',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['*localhost', '*app.exp.poodl.cloud', '*app.poodl.cloud', /^\//],
      }),
    ],
    debug: Config.debugViewer,
    maxBreadcrumbs: 100,
    sampleRate: 1.0,
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

// Tag Manager
import TagManager from 'auth0-tag-manager';
if (Config.googleTagManagerId) {
  TagManager({
    label: 'poodl-app',
    'google-tag-manager': {
      // preloaded: true,
      id: Config.googleTagManagerId,
    },
  });
}

import VueIntercom from 'vue-intercom';
if (Config.intercomId && Config.intercomId !== 'xxxxxxxxxxxx') {
  Vue.use(VueIntercom, { appId: Config.intercomId });
}

import * as filters from '@/filters'; // global filters

Vue.use(Element, {
  size: 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
});
Vue.use(ElInputTag);
Vue.use(require('vue-moment'));

// register global utility filters.
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// global method
Vue.mixin(trace);

Vue.config.productionTip = false;

//headful
import vueHeadful from 'vue-headful';
Vue.component('vue-headful', vueHeadful);

import GlobalEvents from 'vue-global-events';
Vue.component('global-events', GlobalEvents);

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  watch: {
    $route: function() {
      if (Config.intercomId && Vue.$intercom) {
        window.Intercom('update');
      }
    },
  },
});
app.$mount('#app');
