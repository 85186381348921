<template>
  <el-menu class="navbar" mode="horizontal">
    <hamburger
      class="hamburger-container"
      :toggleClick="toggleSideBar"
      :isActive="sidebar.opened"
    ></hamburger>

    <breadcrumb class="breadcrumb-container"></breadcrumb>

    <div class="right-menu">
      <error-log class="errLog-container right-menu-item"></error-log>
      {{ organization }}

      {{ username }}

      <!-- <el-tooltip effect="dark" :content="$t('navbar.screenfull')" placement="bottom"> -->
      <!-- <screenfull class="screenfull right-menu-item"></screenfull> -->
      <!-- </el-tooltip> -->

      <lang-select class="international right-menu-item"></lang-select>

      <!-- <el-tooltip effect="dark" :content="$t('navbar.theme')" placement="bottom">
        <theme-picker class="theme-switch right-menu-item"></theme-picker>
      </el-tooltip> -->

      <el-dropdown class="avatar-container right-menu-item" trigger="click">
        <div class="avatar-wrapper">
          <menu-icon class="menu-icon-size" />
          <!-- <svg-icon class-name='config-icon' icon-class="list" /> -->
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link :to="{ name: 'dashboard' }">
            <span>
              <el-dropdown-item>
                <svg-icon
                  :icon-class="'dashboard'"
                  class="dashboard-menu-icon dropdown-menu-icon"
                />
                {{ $t('navbar.dashboard') }}
              </el-dropdown-item>
            </span>
          </router-link>
          <router-link :to="{ name: 'settingsProfile' }">
            <span>
              <el-dropdown-item>
                <svg-icon :icon-class="'guide'" class="setting-menu-icon dropdown-menu-icon" />
                {{ $t('navbar.settings') }}
              </el-dropdown-item>
            </span>
          </router-link>
          <router-link :to="{ name: 'about' }">
            <span>
              <el-dropdown-item divided>
                <!-- <svg-icon
                  :icon-class="'guide'"
                  class="setting-menu-icon dropdown-menu-icon"
                /> -->
                {{ $t('route.about') }}
              </el-dropdown-item>
            </span>
          </router-link>
          <el-dropdown-item divided>
            <span @click="logout" style="display: block;">
              <logout-icon class="logout-icon-size dropdown-menu-icon" />{{ $t('navbar.logOut') }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <el-dropdown class="avatar-container right-menu-item" trigger="click">
        <div class="avatar-wrapper">
          <img class="user-avatar" :src="avatar+'?imageView2/1/w/80/h/80'">
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/">
            <el-dropdown-item>
              {{$t('navbar.dashboard')}}
            </el-dropdown-item>
          </router-link>
          <a target='_blank' href="https://github.com/PanJiaChen/vue-element-admin/">
            <el-dropdown-item>
              {{$t('navbar.github')}}
            </el-dropdown-item>
          </a>
          <el-dropdown-item divided>
            <span @click="logout" style="display:block;">{{$t('navbar.logOut')}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
    </div>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import ErrorLog from '@/components/ErrorLog';
import Screenfull from '@/components/Screenfull';
import LangSelect from '@/components/LangSelect';

export default {
  components: {
    Breadcrumb,
    Hamburger,
    ErrorLog,
    Screenfull,
    LangSelect,
  },
  computed: {
    username: function() {
      return this.$store.state.user.username;
    },
    organization: function() {
      return this.$store.state.user.organization;
    },
    ...mapGetters(['sidebar', 'name', 'avatar']),
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  min-height: 50px;
  line-height: 50px;
  border-radius: 0 !important;
  .hamburger-container {
    float: left;
    height: 50px;
    padding: 0 10px;
    line-height: 58px;
  }
  .breadcrumb-container {
    float: left;
  }
  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .right-menu {
    float: right;
    height: 50px;
    &:focus {
      outline: none;
    }
    .right-menu-item {
      display: inline-block;
      margin: 0 8px;
    }
    .screenfull {
      margin-top: 15px;
      // height: 20px;
      vertical-align: top;
    }
    .international {
      margin-right: 20px;
      vertical-align: top;
    }
    .theme-switch {
      vertical-align: 15px;
    }
    .avatar-container {
      height: 50px;
      margin-right: 30px;
      .avatar-wrapper {
        position: relative;
        cursor: pointer;
        .user-avatar {
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
        .el-icon-caret-bottom {
          position: absolute;
          top: 25px;
          right: -20px;
          font-size: 12px;
        }
      }
    }
  }
}
.config-icon {
  font-size: 20px;
  vertical-align: -5px !important;
  cursor: pointer;
}
.menu-icon-size {
  font-size: 25px;
  vertical-align: -5px !important;
}
.dashboard-icon-size {
  font-size: initial;
}
.logout-icon-size {
  font-size: initial;
}
.dropdown-menu-icon {
  margin: 0 5px;
}
</style>
