import Vue from 'vue';
import Router from 'vue-router';

/* Layout */
import Layout from '@/views/layout/Layout';

Vue.use(Router);

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
export const constantRouterMap = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    // hidden: true,
    name: 'login',
    meta: { title: 'login' },
  },
  {
    path: '/organization',
    component: () => import('@/views/login/organization'),
    hidden: true,
    name: 'organization',
    meta: { title: 'organization' },
  },
  {
    path: '/reset',
    component: () => import('@/views/login/resetpassword'),
    hidden: true,
    name: 'resetpassword',
    // query: {form: ''},
    // props: true,
    meta: { title: 'resetpassword' },
  },
  {
    path: '/ipaddress',
    component: () => import('@/views/login/addipaddress'),
    hidden: true,
    name: 'addIpAddress',
    // query: {form: ''},
    // props: true,
    meta: { title: 'addIpAddress' },
  },
  // { path: '/login/:organization(\\w+)', component: () => import('@/views/login/index'), hidden: true, name: 'login' },
  // {
  //   path: '/login',
  //   component: () => import('@/views/login/index'),
  //   hidden: true,
  //   children: [{
  //     path: ':organization(\\w+)',
  //     hidden: true
  //   }]
  // },
  {
    path: '/authredirect',
    component: () => import('@/views/login/authredirect'),
    name: 'authredirect',
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true,
  },
  {
    path: '/401',
    //component: () => import('@/views/errorPage/401'),
    component: () => import('@/views/errorPage/404'),
    hidden: true,
  },
  {
    path: '/reregister/:username/:token',
    component: () => import('@/views/settings/reRegisterPassword'),
    name: 'reRegisterPassword',
    hidden: true,
    meta: {
      title: 'registerUser',
    },
  },
  {
    path: '/register/:username/:token',
    component: () => import('@/views/settings/register'),
    name: 'registerUser',
    hidden: true,
    meta: {
      title: 'registerUser',
    },
  },
  {
    path: '/addIpAddress/:username/:token',
    component: () => import('@/views/settings/addIpAddress'),
    name: 'approveAddIpAddress',
    hidden: true,
    meta: {
      title: 'approveAddIpAddress',
    },
  },
  {
    path: '',
    component: Layout,
    redirect: 'dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'dashboard',
        meta: { title: 'dashboard', icon: 'dashboard', noCache: true },
      },
      {
        path: 'settings/profile',
        name: 'settingsProfile',
        component: () => import('@/views/settings/index'),
        meta: { title: 'settings', icon: 'setting', noCache: true },
      },
      {
        path: 'settings/users',
        name: 'settingsUser',
        component: () => import('@/views/settings/index'),
        meta: { title: 'settings', icon: 'setting', noCache: true },
      },
      {
        path: 'settings/groups',
        name: 'settingsGroup',
        component: () => import('@/views/settings/index'),
        meta: { title: 'settings', icon: 'setting', noCache: true },
      },
      {
        path: 'settings/utilization',
        name: 'settingsUtilization',
        component: () => import('@/views/settings/index'),
        meta: { title: 'settings', icon: 'setting', noCache: true },
      },
      {
        path: 'settings/download',
        name: 'settingsDownload',
        component: () => import('@/views/settings/index'),
        meta: { title: 'settings', icon: 'setting', noCache: true },
      },
      {
        path: 'settings/history',
        name: 'settingsHistory',
        component: () => import('@/views/settings/index'),
        meta: { title: 'settings', icon: 'setting', noCache: true },
      },
      {
        path: 'settings/security',
        name: 'settingsSecurity',
        component: () => import('@/views/settings/index'),
        meta: { title: 'settings', icon: 'setting', noCache: true },
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/settings/about'),
        meta: {
          title: 'about',
          // icon: 'guide',
          noCache: false,
        },
        hidden: true,
      },
      {
        path: 'category',
        component: () => import('@/views/routerIndex'),
        // name: 'category',
        // meta: { title: 'category', icon: 'documentation', noCache: true },
        children: [
          {
            path: '',
            name: 'category',
            component: () => import('@/views/category/list'),
            hidden: true,
            meta: {
              title: 'category',
              icon: 'documentation',
              noCache: true,
            },
          },
          {
            path: ':categoryKey(\\w+)',
            name: 'categoryDetail',
            component: () => import('@/views/category/detail'),
            hidden: true,
            meta: {
              title: 'categoryDetail',
              icon: 'documentation',
              noCache: true,
            },
          },
        ],
      },
      {
        path: 'dataset',
        component: () => import('@/views/routerIndex'),
        meta: { title: 'dataset', icon: 'tab', noCache: true },
        children: [
          {
            path: '',
            name: 'dataset',
            component: () => import('@/views/dataset/list'),
            hidden: true,
            meta: { title: 'dataset', icon: 'tab', noCache: true },
          },
          {
            path: 'create',
            component: () => import('@/views/dataset/create'),
            name: 'datasetCreate',
            hidden: true,
            meta: { title: 'datasetCreate', icon: 'tab', noCache: false },
          },
          {
            path: ':datasetPath([\\w:]+)',
            component: () => import('@/views/routerIndex'),
            hidden: true,
            meta: { title: 'datasetDetail', icon: 'tab', noCache: true },
            children: [
              {
                path: '',
                name: 'datasetDetail',
                component: () => import('@/views/dataset/detail'),
                meta: { title: 'datasetDetail', icon: 'tab', noCache: true },
              },
              {
                path: 'edit',
                name: 'datasetDetailEdit',
                component: () => import('@/views/dataset/detail/edit'),
                meta: {
                  title: 'datasetDetailEdit',
                  icon: 'tab',
                  noCache: true,
                },
              },
              {
                path: 'image/:imageKey(\\w+)',
                component: () => import('@/views/dataset/detail/image'),
                hidden: true,
                name: 'datasetDetailImage',
                meta: {
                  title: 'image',
                  icon: 'tab',
                  noCache: true,
                  hiddenTagView: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'preprocessing',
        component: () => import('@/views/routerIndex'),
        meta: { title: 'preprocessing', icon: 'tab', noCache: true },
        children: [
          {
            path: '',
            component: () => import('@/views/preprocessing/list'),
            name: 'preprocessing',
            hidden: true,
            meta: { title: 'preprocessing', icon: 'tab', noCache: true },
          },
        ],
      },
      {
        path: 'annotation',
        component: () => import('@/views/routerIndex'),
        children: [
          {
            path: '',
            component: () => import('@/views/annotation/list'),
            name: 'annotation',
            meta: { title: 'annotation', icon: 'table', noCache: true },
            hidden: true,
          },
          {
            path: ':datasetPath([\\w:]+)',
            component: () => import('@/views/routerIndex'),
            children: [
              {
                path: '',
                component: () => import('@/views/annotation/detail'),
                name: 'annotationDetail',
                meta: {
                  title: 'annotationDetail',
                  icon: 'table',
                  noCache: true,
                },
                hidden: true,
              },
              {
                path: 'image/:imageKey(\\w+)',
                component: () => import('@/views/annotation/detailImage'),
                name: 'annotationDetailImage',
                hidden: true,
                meta: {
                  title: 'annotationDetailImage',
                  icon: 'tab',
                  noCache: true,
                  hiddenTagView: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'compute',
        component: () => import('@/views/routerIndex'),
        name: 'compute',
        meta: { title: 'compute', icon: 'bug', noCache: true },
        children: [
          {
            path: 'condition',
            component: () => import('@/views/routerIndex'),
            meta: { title: 'condition', icon: 'guide', noCache: true },
            children: [
              {
                path: '',
                name: 'computeCondition',
                component: () => import('@/views/compute/condition/list'),
                meta: {
                  title: 'condition',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: 'create',
                name: 'computeConditionCreate',
                component: () => import('@/views/compute/condition/create'),
                meta: {
                  title: 'computeConditionCreate',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: ':conditionPath([\\w:]+)',
                component: () => import('@/views/routerIndex'),
                hidden: true,
                children: [
                  {
                    path: '',
                    name: 'computeConditionDetail',
                    component: () => import('@/views/compute/condition/detail'),
                    meta: {
                      title: 'computeConditionDetail',
                      icon: 'guide',
                      noCache: true,
                    },
                    hidden: true,
                  },
                  {
                    path: 'edit',
                    name: 'computeConditionEdit',
                    component: () => import('@/views/compute/condition/edit'),
                    meta: {
                      title: 'computeConditionEdit',
                      icon: 'guide',
                      noCache: false,
                    },
                    hidden: true,
                  },
                ],
              },
            ],
          },
          {
            path: 'network',
            component: () => import('@/views/routerIndex'),
            children: [
              {
                path: '',
                name: 'computeNetwork',
                component: () => import('@/views/compute/network/list'),
                meta: {
                  title: 'network',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: 'upload',
                name: 'computeNetworkUpload',
                component: () => import('@/views/compute/network/upload'),
                meta: {
                  title: 'computeNetworkUpload',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: 'create',
                name: 'computeNetworkCreate',
                component: () => import('@/views/compute/network/create'),
                meta: {
                  title: 'computeNetworkCreate',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: ':networkKey([\\w]+)',
                name: 'computeNetworkDetail',
                component: () => import('@/views/compute/network/detail'),
                meta: {
                  title: 'computeNetworkDetail',
                  icon: 'guide',
                  noCache: true,
                },
                hidden: true,
              },
            ],
          },
          {
            path: 'library',
            component: () => import('@/views/routerIndex'),
            children: [
              {
                path: '',
                name: 'computeLibrary',
                component: () => import('@/views/compute/library/list'),
                meta: {
                  title: 'library',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: 'create',
                name: 'computeLibraryCreate',
                component: () => import('@/views/compute/library/create'),
                meta: {
                  title: 'computeLibraryCreate',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: ':libraryKey([\\w]+)',
                name: 'computeLibraryDetail',
                component: () => import('@/views/compute/library/detail'),
                meta: {
                  title: 'computeLibraryDetail',
                  icon: 'guide',
                  noCache: true,
                },
                hidden: true,
              },
            ],
          },
          {
            path: 'job',
            component: () => import('@/views/routerIndex'),
            children: [
              {
                path: '',
                name: 'computeJob',
                component: () => import('@/views/compute/job/list'),
                meta: {
                  title: 'job',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: 'create',
                name: 'computeJobCreate',
                component: () => import('@/views/compute/job/create'),
                meta: {
                  title: 'computeJobCreate',
                  icon: 'guide',
                  noCache: false,
                },
                hidden: true,
              },
              {
                path: ':jobKey([\\w]+)',
                component: () => import('@/views/routerIndex'),
                hidden: true,
                children: [
                  {
                    path: '',
                    name: 'computeJobDetail',
                    component: () => import('@/views/compute/job/detail'),
                    meta: {
                      title: 'computeJobDetail',
                      icon: 'guide',
                      noCache: true,
                    },
                    hidden: true,
                  },
                  {
                    path: 'image/:imageKey(\\w+)',
                    name: 'computeJobDetailImage',
                    component: () => import('@/views/compute/job/image'),
                    meta: {
                      title: 'image',
                      icon: 'tab',
                      noCache: true,
                      hiddenTagView: true,
                    },
                    hidden: true,
                  },
                  {
                    path: 'log',
                    name: 'computeJobLog',
                    component: () => import('@/views/compute/job/log'),
                    meta: {
                      title: 'computeJobLog',
                      icon: 'guide',
                      noCache: true,
                    },
                  },
                  {
                    path: 'report',
                    name: 'computeJobReport',
                    component: () => import('@/views/compute/job/report'),
                    meta: {
                      title: 'computeJobReport',
                      icon: 'guide',
                      noCache: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const router = new Router({
  mode: 'history', // require service support
  // scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});

export default router;
