/* eslint-disable no-console */
import { trace } from '@/utils/trace';
import { register } from 'register-service-worker';
import Cookies from 'js-cookie';
import Config from '@/config';

if (Config.environment === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      trace(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      trace('Service worker has been registered.');
    },
    cached() {
      trace('Content has been cached for offline use.');
    },
    updatefound() {
      trace('New content is downloading.');
    },
    async updated() {
      trace('New content is available; please refresh.');
      // const nowVersion = require('../package.json').version;
      const nowVersion = await import('../package.json').then(({ version: data }) => data);
      const storedVersion = Cookies.get('version');
      trace('serviceWorker nowVersion', nowVersion);
      trace('serviceWorker storedVersion', storedVersion);
      if (storedVersion !== nowVersion) {
        Cookies.set('version', nowVersion);
        location.reload(true);
      }
    },
    offline() {
      trace('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
