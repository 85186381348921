<script>
import Config from '@/config';
// intercom chat application
export default {
  computed: {
    userKey() {
      return this.$store && this.$store.getters.key;
    },
  },
  watch: {
    userKey() {
      this.bootIntercom();
    },
  },
  mounted() {
    this.bootIntercom();
  },
  methods: {
    bootIntercom() {
      if (Config.intercomId && this.userKey && this.$intercom) {
        let user = {
          loggin: false,
        };
        user.loggin = true;
        user.user_id = 'poodl_' + this.$store.getters.key;
        user.name = this.$store.getters.name;
        user.email = this.$store.getters.email;
        user.application = 'poodl';
        user.company = {
          company_id: this.$store.getters.groupKey,
          name: this.$store.getters.organization,
        };

        this.trace('intercom user', user);
        this.$intercom.boot(user);
      }
    },
  },
};
</script>
