import requestAuth from '@/utils/requestAuth';
import requestV1 from '@/utils/requestV1';
import requestAuthWithToken from '@/utils/requestAuthWithToken';

export function loginByUsername(organization, username, password) {
  const data = {
    organization_key: organization,
    username: username,
    password: password,
    grant_type: 'password',
  };

  // console.log(data)
  return requestAuth({
    url: '/token',
    method: 'post',
    data,
  });
}

export function loginRefresh(tokenRefresh) {
  const data = {
    refresh_token: tokenRefresh,
    grant_type: 'refresh_token',
  };

  // console.log(data)
  return requestAuth({
    url: '/token',
    method: 'post',
    data,
  });
}

export function logout() {
  return requestAuth({
    url: '/logout',
    method: 'post',
  });
}

export function getUserInfo() {
  return requestAuthWithToken({
    url: '/user-info',
    method: 'get',
  });
}

export function sendResetPassEmail(organization, username, mailaddress) {
  const data = {
    organization: organization,
    username: username,
    mailaddress: mailaddress,
  };
  return requestV1({
    url: '/passwords/request',
    method: 'post',
    data,
  });
}

export function confirmToken(userName, token) {
  return requestV1({
    url: '/passwords/confirm/' + userName + '/' + token,
    method: 'get',
  });
}

export function confirmIpaddressRefreshToken(userName, token) {
  const data = {
    username: userName,
    token: token,
  };
  return requestV1({
    url: '/ips/approve/' + userName + '/' + token,
    method: 'put',
    data,
  });
}

export function resetPassword(data, userName, token) {
  return requestV1({
    url: '/passwords/reset/' + userName + '/' + token,
    method: 'post',
    data,
  });
}

export function addIpAddress(organization, username, mailaddress, ipAddress) {
  const data = {
    organization: organization,
    username: username,
    mailaddress: mailaddress,
    ipAddress: ipAddress,
  };
  return requestV1({
    url: '/ips/request',
    method: 'post',
    data,
  });
}
