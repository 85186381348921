<template>
  <div class="menu-wrapper">
    <router-link v-if="!item.children" :to="resolvePath(item.name)">
      <el-menu-item :index="item.name" :class="{ 'submenu-title-noDropdown': !isNest }">
        <svg-icon v-if="item.icon" :icon-class="item.icon"></svg-icon>
        <span v-if="resolveTitle(item.name)" slot="title">{{
          generateTitle(resolveTitle(item.name))
        }}</span>
      </el-menu-item>
    </router-link>

    <el-submenu v-else :index="item.name">
      <template slot="title">
        <svg-icon v-if="item.icon" :icon-class="item.icon"></svg-icon>
        <span slot="title">{{ generateTitle(resolveTitle(item.name)) }}</span>
      </template>

      <template v-for="child in item.children">
        <sidebar-item
          :is-nest="true"
          class="nest-menu"
          v-if="child.children && child.children.length > 0"
          :item="child"
          :key="child.name"
          :base-path="resolvePath(child.name)"
        ></sidebar-item>

        <router-link v-else :to="resolvePath(child.name)" :key="child.name">
          <el-menu-item :index="child.name">
            <svg-icon v-if="child.icon" :icon-class="child.icon"></svg-icon>
            <span slot="title">{{ generateTitle(resolveTitle(child.name)) }}</span>
          </el-menu-item>
        </router-link>
      </template>
    </el-submenu>
  </div>
</template>

<script>
// import path from 'path';
import { generateTitle } from '@/utils/i18n';

export default {
  name: 'SidebarItem',
  props: {
    item: {
      // route配置json
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  methods: {
    hasOneShowingChildren(children) {
      const showingChildren = children.filter((item) => {
        return !item.hidden;
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    resolvePath(name) {
      // return path.resolve(this.basePath, ...paths);
      return { name: name };
    },
    resolveTitle(name) {
      const props = this.$router.resolve({
        name: name,
      });
      return props.route && props.route.meta && props.route.meta.title;
    },
    generateTitle,
  },
};
</script>
