import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import errorLog from './modules/errorLog';
import permission from './modules/permission';
import tagsView from './modules/tagsView';
import user from './modules/user';
import version from './modules/version';
import autoScroll from './modules/autoScroll';
// import upload from './modules/upload';
import getters from './getters';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    errorLog,
    permission,
    tagsView,
    user,
    version,
    autoScroll,
    // upload,
  },
  getters,
});

export default store;
