<template>
  <el-scrollbar wrapClass="scrollbar-wrapper">
    <el-menu
      mode="vertical"
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      background-color="#232b34"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
    >
      <!-- background-color="#304156" -->
      <sidebar-item
        v-for="route in menuItems"
        :key="route.name"
        :item="route"
        :base-path="route.path"
      ></sidebar-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';

export default {
  components: { SidebarItem },
  data() {
    return {
      menuItems: [],
    };
  },
  computed: {
    ...mapGetters(['sidebar']),
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
  mounted() {
    // console.log(this.permission_routers);
    this.menuItems = [
      {
        name: 'dashboard',
        icon: 'dashboard',
      },
      {
        name: 'category',
        icon: 'documentation',
      },
      {
        name: 'dataset',
        icon: 'tab',
      },
      {
        name: 'annotation',
        icon: 'table',
      },
      {
        name: 'preprocessing',
        icon: 'table',
      },
      {
        name: 'compute',
        icon: 'bug',
        children: [
          {
            name: 'computeCondition',
            icon: 'guide',
          },
          {
            name: 'computeNetwork',
            icon: 'guide',
          },
          {
            name: 'computeLibrary',
            icon: 'guide',
          },
        ],
      },
      {
        name: 'computeJob',
        icon: 'guide',
      },
    ];
  },
};
</script>
