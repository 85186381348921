<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';

export default {
  name: 'App',
  data() {
    const p = require('../package.json');
    return {
      viewVersion: p.version,
    };
  },
  async mounted() {
    // sentryにバージョン情報をセット
    // if (!this.$store.state.version.resource || !this.$store.state.version.auth || !this.$store.state.version.engine) {
    if (!this.$store.state.version.resource) {
      await this.$store.dispatch('setVersions');
    }
    Sentry.configureScope((scope) => {
      scope.setTag('view.version', this.viewVersion);
      scope.setTag('resource.version', this.$store.state.version.resource);
      scope.setTag('auth.version', this.$store.state.version.auth);
      scope.setTag('engine.version', this.$store.state.version.engine);
    });
  },
};
</script>
