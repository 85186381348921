'use strict';
/* eslint-disable no-console */

class Host {
  constructor(host, port) {
    this.host = host;
    this.port = port;
    this.url = this.combineUrl(this.host, this.port);
  }

  combineUrl(host, port) {
    if (!port) {
      return host;
    } else if (port === 80) {
      return host;
    }
    return host + ':' + port;
  }
}

class Connect {
  constructor(self, auth, resource) {
    this.self = self;
    this.auth = auth;
    this.resource = resource;
  }
}

class Container {
  constructor(args) {
    this.selfHostBase = args.selfHostBase || 'https://app.poodl.cloud';
    this.selfPortBase = args.selfPortBase || 80;
    this.authHostBase = args.authHostBase || 'https://app.poodl.cloud';
    this.authPortBase = args.authPortBase || 80;
    this.resourceHostBase = args.resourceHostBase || 'https://app.poodl.cloud';
    this.resourcePortBase = args.resourcePortBase || 80;
    this.organization = args.organization;

    this.environment = args.environment;
    this.debugViewer = args.debugViewer;
    this.googleTagManagerId = args.googleTagManagerId;
    this.intercomId = args.intercomId;
    this.sentryDsn = args.sentryDsn;

    this.update();
  }

  trace(...s) {
    if (this.debugViewer && typeof console.log != 'undefined') {
      console.log(...s);
    }
  }

  update(subDomain = '') {
    if (this.organization) {
      subDomain = '';
    }

    this.trace('update subdomain', subDomain, this.organization);

    const host = this.setSubDomain(this.selfHostBase, subDomain);
    const authHost = this.setSubDomain(this.authHostBase, subDomain);
    const resourceHost = this.setSubDomain(this.resourceHostBase, subDomain);

    const build = new Connect(
      new Host(host, this.selfPortBase),
      new Host(authHost, this.authPortBase),
      new Host(resourceHost, this.resourcePortBase)
    );

    this.host = build.self.host;
    this.port = build.self.port;
    this.url = build.self.url;

    this.authHost = build.auth.host;
    this.authPort = build.auth.port;
    this.authUrl = build.auth.url;

    this.resourceHost = build.resource.host;
    this.resourcePort = build.resource.port;
    this.resourceUrl = build.resource.url;
  }

  setSubDomain(host, subDomain) {
    if (subDomain === '') {
      return host;
    }

    const parts = host.split(/:\/\//);
    const protocol = parts[0];
    const name = parts[1];

    return protocol + '://' + subDomain + '.' + name;
  }
}

let Config = null;
if (process.env.VUE_APP_BUILD_ENV === 'dynamic') {
  Config = new Container({
    environment: '$VUE_APP_ENV',
    debugViewer: '$VUE_APP_DEBUG' === 'true',
    selfHostBase: '$VUE_APP_SELF_HOST',
    selfPortBase: parseInt('$VUE_APP_SELF_PORT'),
    authHostBase: '$VUE_APP_AUTH_HOST',
    authPortBase: parseInt('$VUE_APP_AUTH_PORT'),
    resourceHostBase: '$VUE_APP_RESOURCE_HOST',
    resourcePortBase: parseInt('$VUE_APP_RESOURCE_PORT'),
    organization: '$VUE_APP_ORGANIZATION',
    googleTagManagerId: '$VUE_APP_GOOGLE_TAG_MANAGER_ID',
    intercomId: '$VUE_APP_INTERCOM_ID',
    sentryDsn: '$VUE_APP_SENTRY_DSN',
  });
} else {
  Config = new Container({
    environment: process.env.VUE_APP_ENV,
    debugViewer: process.env.VUE_APP_DEBUG === 'true',
    selfHostBase: process.env.VUE_APP_SELF_HOST,
    selfPortBase: parseInt(process.env.VUE_APP_SELF_PORT),
    authHostBase: process.env.VUE_APP_AUTH_HOST,
    authPortBase: parseInt(process.env.VUE_APP_AUTH_PORT),
    resourceHostBase: process.env.VUE_APP_RESOURCE_HOST,
    resourcePortBase: parseInt(process.env.VUE_APP_RESOURCE_PORT),
    organization: process.env.VUE_APP_ORGANIZATION,
    googleTagManagerId: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
    intercomId: process.env.VUE_APP_INTERCOM_ID,
    sentryDsn: process.env.VUE_APP_SENTRY_DSN,
  });
}
export default Config;
