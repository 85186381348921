import router from '@/router';
import store from '@/store';
import moment from 'moment';
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getTokenAccess, getTokenRefresh } from '@/utils/auth'; // getToken from cookie
import jwtDecode from 'jwt-decode';
import { getDomainOrganization } from './utils/organization';
import Config from '@/config';
import Cookies from 'js-cookie';
import { trace } from '@/utils/trace';

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = [
  'about',
  'resetpassword',
  'reRegisterPassword',
  'addIpAddress',
  'approveAddIpAddress',
];
const whiteListLogin = ['login', 'registerUser', 'authredirect']; // no redirect whitelist
const whiteListOragnization = ['organization'];

// function getSubdomain() {
//   const baseUrl = Config.hostBase;
//   var elemBase = document.createElement('a');
//   elemBase.href = baseUrl;

//   const url = document.location.href;
//   var elem = document.createElement('a');
//   elem.href = url;

//   const subdomain = elem.hostname.substr(0, elem.hostname.length - elemBase.hostname.length - 1);

//   return {
//     subdomain: subdomain,
//     hostname: elem.hostname,
//     pathname: elem.pathname,
//   }
// }

router.beforeEach(async (to, from, next) => {
  // force-reload if having old version info in cookie
  const nowVersion = await import('../package.json').then(({ version: data }) => data);
  // const nowVersion = require('../package.json').version;
  const storedVersion = Cookies.get('version');
  trace('beforeEach nowVersion', nowVersion);
  trace('beforeEach storedVersion', storedVersion);
  if (storedVersion !== nowVersion) {
    Cookies.set('version', nowVersion);
    location.reload();
  }

  NProgress.start(); // start progress bar

  const accessToken = getTokenAccess();
  const organization = getDomainOrganization();
  const storedOrganization = store.getters ? store.getters.organization : null;
  trace(storedOrganization);

  if (organization) {
    Config.update(organization);
  }

  if (accessToken) {
    // token
    let decodedToken = jwtDecode(accessToken);

    // subdomain
    if (decodedToken && decodedToken.user && decodedToken.user.organization_key) {
      await store
        .dispatch('SetOrganization', decodedToken.user.organization_key)
        .catch(async (err) => {
          await store.dispatch('FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again');
            next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } });
            NProgress.done(); // if current page is dashboard will not trigger afterEach hook, so manually handle it
          });
        });
    } else {
      await store.dispatch('FedLogOut').then(() => {
        Message.error('Verification failed, please login again');
        next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } });
        NProgress.done(); // if current page is dashboard will not trigger afterEach hook, so manually handle it
      });
    }

    // set roles
    if (decodedToken && decodedToken.user && decodedToken.user.roles) {
      await store.dispatch('SetRoles', decodedToken.user.roles);
    } else {
      await store.dispatch('FedLogOut').then(() => {
        Message.error('Verification failed, please login again');
        next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } });
        NProgress.done(); // if current page is dashboard will not trigger afterEach hook, so manually handle it
      });
    }

    if (!store.state.user.key) {
      await store.dispatch('GetUserInfo').catch((err) => {
        trace('GetUserInfo', err);
        store.dispatch('FedLogOut').then(() => {
          trace('108');
          Message.error(err || 'Verification failed, please login again');
          // next({ path: '/login' });
          next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } });
        });
        trace('113');
        return;
      });
      const newAccessToken = getTokenAccess();
      decodedToken = jwtDecode(newAccessToken);
      trace('106');
    }

    // exp date
    const isExpired = moment.utc(decodedToken.exp).isBefore(moment());
    trace('111', isExpired);

    // determine if there has token
    /* has token*/
    // const subdomain = getSubdomain();
    if (whiteListOragnization.indexOf(to.name) !== -1) {
      const org = decodedToken.user.organization_key;
      Config.update(org);

      document.location.href = Config.url + '/login';
      // next({ path: '/' }); // already loginned
      // NProgress.done(); // if current page is dashboard will not trigger afterEach hook, so manually handle it
    } else if (whiteListLogin.indexOf(to.name) !== -1) {
      next({ path: '/' }); // already loginned
      NProgress.done(); // if current page is dashboard will not trigger afterEach hook, so manually handle it
      return;
    } else {
      if (isExpired) {
        // 判断当前用户是否已拉取完user_info信息
        const refreshToken = getTokenRefresh();
        // await store.dispatch('LoginRefresh', refreshToken);
        await store.dispatch('LoginRefresh', refreshToken).catch((err) => {
          trace(err);
          store.dispatch('FedLogOut').then(() => {
            Message.error(err || 'Verification failed, please login again');
            next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } });
          });
          return;
        });

        // user infoが取得できた時
        next({ ...to, replace: true });
        // store.dispatch('GenerateRoutes').then(() => {
        //   router.addRoutes(store.getters.addRouters); // 动态添加可访问路由表
        //   next({ ...to, replace: true }); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
        // });
        return;
      } else {
        next();
        return;
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.name) !== -1) {
      next();
    } else if (whiteListOragnization.indexOf(to.name) !== -1) {
      // 在免登录白名单，直接进入
      if (organization) {
        next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } });
      } else {
        next();
      }
    } else if (whiteListLogin.indexOf(to.name) !== -1) {
      // 在免登录白名单，直接进入
      if (organization) {
        next();
      } else {
        next('/organization');
      }
    } else {
      next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } }); // 否则全部重定向到登录页
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
    return;
  }
  next({ path: '/login', query: { from: window.location.pathname.split('/').slice(1) } }); // 否则全部重定向到登录页
  NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
