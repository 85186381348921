const getters = {
  sidebar: (state) => state.app.sidebar,
  language: (state) => state.app.language,
  device: (state) => state.app.device,
  config: (state) => state.app.config,
  subdomain: (state) => state.app.subdomain,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.tokenAccess,
  avatar: (state) => state.user.avatar,
  key: (state) => state.user.key,
  name: (state) => state.user.name,
  email: (state) => state.user.email,
  created_at: (state) => state.user.created_at,
  groupKey: (state) => state.user.groupKey,
  organization: (state) => state.user.organization,
  introduction: (state) => state.user.introduction,
  status: (state) => state.user.status,
  // roles: (state) => state.user.roles,
  setting: (state) => state.user.setting,
  enabled: (state) => state.user.enabled,
  permission_routers: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,
  errorLogs: (state) => state.errorLog.logs,
  autoScroll: (state) => state.autoScroll.autoScrollStatus,
};
export default getters;
