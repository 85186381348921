<template>
  <div class="tags-view-container hidden-xs-only">
    <scroll-pane class="tags-view-wrapper" ref="scrollPane">
      <router-link
        ref="tag"
        class="tags-view-item"
        :class="isActive(tag) ? 'active' : ''"
        v-for="tag in Array.from(visitedViews)"
        :to="tag"
        :key="tag.path"
        @contextmenu.prevent.native="openMenu(tag, $event)"
      >
        {{ generateTitle(tag.title) }}
        <span class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)"></span>
      </router-link>
    </scroll-pane>
    <ul class="contextmenu" v-show="visible" :style="{ left: left + 'px', top: top + 'px' }">
      <li @click="closeSelectedTag(selectedTag)">{{ $t('tagsView.close') }}</li>
      <li @click="closeOthersTags">{{ $t('tagsView.closeOthers') }}</li>
      <li @click="closeAllTags">{{ $t('tagsView.closeAll') }}</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from '@/components/ScrollPane';
import { generateTitle } from '@/utils/i18n';

export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
    };
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    },
  },
  watch: {
    $route() {
      this.addViewTags();
      this.moveToCurrentTag();
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu);
      } else {
        document.body.removeEventListener('click', this.closeMenu);
      }
    },
  },
  mounted() {
    this.addViewTags();
  },
  methods: {
    generateTitle, // generateTitle by vue-i18n
    generateRoute() {
      if (!this.$route.name) {
        return false;
      }
      if (this.$route.meta && this.$route.meta.hiddenTagView) {
        return false;
      }
      return this.$route;
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    addViewTags() {
      const route = this.generateRoute();
      if (!route) {
        return false;
      }
      this.$store.dispatch('addVisitedViews', route);
    },
    moveToCurrentTag() {
      this.$nextTick(() => {
        const tags = this.$refs.tag;
        if (tags === undefined) {
          return;
        }
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag.$el);
            break;
          }
        }
      });
    },
    closeSelectedTag(view) {
      this.$store.dispatch('delVisitedViews', view).then((views) => {
        if (this.isActive(view)) {
          const latestView = views.slice(-1)[0];
          if (latestView) {
            this.$router.push(latestView);
          } else {
            this.$router.push('/');
          }
        }
      });
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag);
      this.$store.dispatch('delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag();
      });
    },
    closeAllTags() {
      this.$store.dispatch('delAllViews');
      this.$router.push('/');
    },
    openMenu(tag, e) {
      this.visible = true;
      this.selectedTag = tag;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      this.left = e.clientX - offsetLeft + 15; // 15: margin right
      this.top = e.clientY;
    },
    closeMenu() {
      this.visible = false;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.tags-view-container {
  .tags-view-wrapper {
    height: 34px;
    background: #ffffff;
    border-bottom: 1px solid #d8dce5;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    .tags-view-item {
      position: relative;
      display: inline-block;
      height: 26px;
      margin-top: 4px;
      margin-left: 5px;
      padding: 0 8px;
      color: #495060;
      font-size: 12px;
      line-height: 26px;
      background: #ffffff;
      border: 1px solid #d8dce5;
      &:first-of-type {
        margin-left: 15px;
      }
      &.active {
        color: #ffffff;
        background-color: #42b983;
        border-color: #42b983;
        &:before {
          position: relative;
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 2px;
          background: #ffffff;
          border-radius: 50%;
          content: '';
        }
      }
    }
  }
  .contextmenu {
    position: absolute;
    z-index: 100;
    margin: 0;
    padding: 5px 0;
    color: #333333;
    font-weight: 400;
    font-size: 12px;
    list-style-type: none;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eeeeee;
      }
    }
  }
}
</style>

<style rel="stylesheet/scss" lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      text-align: center;
      vertical-align: 2px;
      border-radius: 50%;
      transform-origin: 100% 50%;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      &:before {
        display: inline-block;
        vertical-align: -3px;
        transform: scale(0.6);
      }
      &:hover {
        color: #ffffff;
        background-color: #b4bccc;
      }
    }
  }
}
</style>
