import Config from '@/config';

function getHostName(host) {
  if (!host) {
    host = Config.host;
  }
  const parts = host.split(/:\/\//);
  // const protocol = parts[0];
  const name = parts[1];
  return name;
}

export function getDomainOrganization() {
  const hostAndPort = window.location.host;
  const hostParts = hostAndPort.split(':');
  // let port = 80;
  let host = hostAndPort;
  if (hostParts.length > 1) {
    host = hostParts[0];
    // port = hostParts[1];
  }
  const parts = host.split('.');

  if (Config.organization) {
    return Config.organization;
  }

  let subdomain;
  if (getHostName(Config.selfHostBase) === host) {
    subdomain = '';
  } else {
    subdomain = parts[0];
  }
  return subdomain;
}
