<template>
  <section class="app-main">
    <transition :name="transitionName" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="key"></router-view>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  data() {
    return {
      transitionName: '',
    };
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews;
    },
    key() {
      // return this.$route && this.$route.name;
      const smoothTransitionRoutes = [
        'datasetDetail',
        'datasetDetailEdit',
        'datasetDetailImage',
        'annotationDetailImage',
        'computeJobDetail',
        'computeJobDetailImage',
      ];
      if (smoothTransitionRoutes.includes(this.$route.name)) {
        return this.$route.name;
      }
      return this.$route.fullPath;
    },
  },
  watch: {
    $route(to, from) {
      const toName = to.meta ? to.meta.title : '';
      const fromName = from.meta ? from.meta.title : '';
      const enableTransitionName = 'fade-transform';
      if (!toName || !fromName) {
        this.transitionName = enableTransitionName;
      } else if (toName != fromName) {
        this.transitionName = enableTransitionName;
      } else {
        this.transitionName = '';
      }
    },
  },
};
</script>

<style scoped>
.app-main {
  position: relative;

  /* 84 = navbar + tags-view = 50 +34 */
  min-height: calc(100vh - 84px);

  /* overflow: hidden; */
}
</style>
