/* eslint-disable no-console */
import Config from '@/config';

/** デバッグモードでConsoleAPIが有効な場合にログを出力する */
function trace(...s) {
  if (Config.debugViewer && typeof console.log != 'undefined') {
    console.log(...s);
  }
}

export { trace };
